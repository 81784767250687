<template>
    <div>
        <h1 class="page__title">Создать абонемент</h1>

        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-name" class="form-label">Название <sup>*</sup></label>
                    <input
                        id="form-name"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.name"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-lessons-number" class="form-label">Кол-во занятий <sup>*</sup></label>
                    <input
                        id="form-lessons-number"
                        type="number"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.lessons_number"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-duration" class="form-label">Продолжительность <sup>*</sup></label>
                    <input
                        id="form-duration"
                        type="number"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.duration"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-type" class="form-label">Тип <sup>*</sup></label>
                    <Multiselect
                        id="form-type"
                        name="type"
                        :value="null"
                        :searchable="false"
                        track-by="name"
                        label="name"
                        valueProp="id"
                        :options="[
                            {
                                id: 1,
                                name: 'Индивидуальные',
                            },
                            {
                                id: 2,
                                name: 'Групповые',
                            },
                        ]"
                        :can-clear="false"
                        placeholder="Не выбрано"
                        noResultsText="Не найдено"
                        noOptionsText="Не найдено"
                        v-model="form.type"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-price" class="form-label">Цена <sup>*</sup></label>
                    <input
                        id="form-price"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.price"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-profit" class="form-label">Скидка</label>
                    <input
                        id="form-profit"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        required
                        autocomplete="off"
                        v-model="form.profit"
                    />
                </div>

                <div v-if="affiliates && affiliates.length > 0" class="col-span-6">
                    <label for="form-affiliates" class="form-label">Филиал <sup>*</sup></label>
                    <Multiselect
                        id="form-affiliates"
                        name="type"
                        :value="null"
                        :searchable="false"
                        track-by="name"
                        label="name"
                        valueProp="id"
                        :options="affiliates"
                        :can-clear="false"
                        placeholder="Не выбрано"
                        noResultsText="Не найдено"
                        noOptionsText="Не найдено"
                        v-model="form.affiliate_id"
                    />
                </div>

                <div class="col-span-12">
                    <label class="form-label">Описание</label>
                    <CustomWyswig v-model="form.description" />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link to="/products" class="btn btn-secondary w-24 mr-2 mb-2">Отмена</router-link>
                    <button type="button" class="btn btn-primary mr-2 mb-2" @click="() => save(true)">
                        Сохранить и продолжить
                    </button>
                    <button type="button" class="btn btn-primary mb-2" @click="save(false)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { errorResponse, formPreparation } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import CustomWyswig from '@/components/base/custom-wyswig/Main.vue';
import { mapGetters } from 'vuex';

export default {
    components: { Preloader, CustomWyswig },
    name: 'Create',
    mixins: [errorResponse, formPreparation],
    data() {
        return {
            loading: false,
            form: {
                name: '',
                lessons_number: '',
                duration: '',
                type: '',
                price: '',
                profit: '',
                description: '',
                affiliate_id: null,
            },
            affiliates: [],
        };
    },
    async created() {
        await this.fetchAffiliates();
    },
    computed: {
        ...mapGetters({
            getUser: 'user/getUser',
        }),
    },
    methods: {
        async fetchAffiliates() {
            try {
                const ids =
                    this.getUser && this.getUser.assigned_affiliates && this.getUser.assigned_affiliates.length > 0
                        ? this.getUser.assigned_affiliates.join(',')
                        : null;
                const { data } = await this.axios.get('/affiliates', { params: { ids, paginate: false } });
                this.affiliates = data.data;
            } catch (ex) {
                console.error('cant fetch affiliates: ' + ex);
            }
        },
        clearForm() {
            for (const key in this.form) {
                if (key === 'published') {
                    this.form.published = false;
                    continue;
                }

                this.form[key] = null;
            }
        },
        async save(flag) {
            this.loading = true;
            try {
                this.loading = false;
                const formData = this.formPreparation();
                const { data } = await this.axios.post('/products', formData);

                if (flag) {
                    await this.$router.push({ name: 'productsEdit', params: { id: data.data.id } });
                    this.$notify('Запись добавлена');
                } else {
                    await this.$router.push({ name: 'products' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
    },
};
</script>

<style></style>
